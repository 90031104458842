import scrollIntoView from 'scroll-into-view-if-needed';
import React from 'react';
import { IScrollIntoViewProps, ITargetProps } from './scrollIntoView.types';

export const withScrollIntoView = <T extends ITargetProps>(
  Target: React.ComponentType<T>,
) => {
  return class extends React.Component<IScrollIntoViewProps & T> {
    ref: HTMLElement;

    handleRef = (ref: HTMLElement) => {
      this.ref = ref;
      this.props.onRef && this.props.onRef(ref);
    };

    componentWillReceiveProps(nextProps: Readonly<IScrollIntoViewProps>) {
      if (nextProps.shouldScrollIntoView && !this.props.shouldScrollIntoView) {
        if (!this.ref) {
          return;
        }

        scrollIntoView(this.ref, {
          scrollMode: 'if-needed',
        });
      }
    }

    render() {
      return <Target {...this.props} onRef={this.handleRef} />;
    }
  };
};
