import React from 'react';
import { Button } from '@wix/wix-base-ui';
import { ILinkProps } from './Link.types';
import classnames from 'classnames';
import styles from './Link.scss';
import { noop } from '../../utils/noop';

export class Link extends React.Component<ILinkProps> {
  static defaultProps = {
    dataHook: 'es-link',
    onClick: noop,
    onHover: noop,
  };

  handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onClick();
  };

  handleHover = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    this.props.onHover();
  };

  render() {
    const { children, dataHook, icon, className, selected } = this.props;
    return (
      <div
        data-hook="es-link-container"
        className={className}
        onMouseEnter={this.handleHover}
      >
        <Button
          className={classnames('btn-text', styles.container, {
            [styles.withIcon]: Boolean(icon),
            [styles.selected]: selected,
          })}
          dataHook={dataHook}
          onClick={this.handleClick}
        >
          {icon}
          <span className={styles.text}>{children}</span>
        </Button>
      </div>
    );
  }
}
