import React from 'react';

import { IIconProps } from './Icon.types';

import { st, classes } from './Icon.st.css';

export const Icon: React.FunctionComponent<IIconProps> = ({
  children,
  layout,
  colors,
  size,
  bordered = true,
}) => (
  <div
    className={st(classes.root, {
      [layout]: true,
      [colors]: true,
      bordered,
    })}
    style={{ width: size, height: size }}
  >
    {children}
  </div>
);
