import React from 'react';
import styles from './Title.scss';
import { ITitleProps } from './Title.types';

export const Title: React.FunctionComponent<ITitleProps> = ({
  children,
  suffix,
}) => (
  <div className={styles.container}>
    <span className={styles.text}>{children}</span>
    <span className={styles.suffix}>{suffix}</span>
  </div>
);
