exports = module.exports = require("../../../node_modules/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".xst8V{box-sizing:border-box;width:100%;min-height:60px;background-color:#ffffff;display:flex;align-items:center;padding:8px 18px}.xst8V *,.xst8V *::before,.xst8V *::after{box-sizing:border-box}section.xst8V:first-of-type{padding-top:14px}section.xst8V:last-of-type{padding-bottom:14px}.xst8V:focus,.xst8V._2pX0i{background-color:#eaf7ff;cursor:pointer}.xst8V:focus ._3-FHy,.xst8V._2pX0i ._3-FHy{opacity:1}._291ks{cursor:pointer}._291ks .control-label-base{display:block;display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;line-clamp:1;-webkit-line-clamp:1}._1NB_O{cursor:pointer;margin-top:2px}._1NB_O .control-label-base{display:block;display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;line-clamp:2;-webkit-line-clamp:2}._3-FHy{opacity:0;display:flex;justify-content:flex-end;padding-left:6px}.ZzXIF{flex-grow:1;display:flex;flex-direction:column;justify-content:center}:vars{--card-min-height: 60px;--card-icon-size: 36px;--card-margin-right: 12px;--card-hover-color: #eaf7ff}._2Ks1X{width:36px;height:36px;margin-right:12px;flex-shrink:0}\n", ""]);

// Exports
exports.locals = {
	"container": "xst8V",
	"selected": "_2pX0i",
	"cta": "_3-FHy",
	"title": "_291ks",
	"description": "_1NB_O",
	"content": "ZzXIF",
	"icon": "_2Ks1X"
};