import React from 'react';
import { TextLabel } from '@wix/wix-base-ui';
import { IEmptyResultsProps } from './EmptyResults.types';
import { st, classes } from './EmptyResults.st.css';
import EmptyResultsImage from './EmptyResults.svg';

export const EmptyResults: React.FunctionComponent<IEmptyResultsProps> = ({
  title,
  subtitle,
  footer,
}) => (
  <section className={st(classes.root)}>
    <div
      className={classes.content}
      style={{ backgroundImage: `url("${EmptyResultsImage}")` }}
    >
      <TextLabel
        shouldTranslate={false}
        type="T09"
        value={title}
        enableEllipsis={false}
      />
      <TextLabel
        type="T07"
        value={subtitle}
        className={classes.subtitle}
        enableEllipsis={false}
        shouldTranslate={false}
      />
    </div>

    {footer && (
      <footer data-hook="es-empty-footer" className={classes.footer}>
        {footer}
      </footer>
    )}
  </section>
);
