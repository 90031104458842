import React from 'react';
import classnames from 'classnames';
import { TextLabel } from '@wix/wix-base-ui';
import { ICardProps } from './Card.types';
import { noop } from '../../utils/noop';
import styles from './Card.scss';

export class Card extends React.Component<ICardProps> {
  static defaultProps = {
    dataHook: 'es-card',
    onClick: noop,
    onHover: noop,
    onBlur: noop,
    onRef: noop,
  };

  handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    this.props.onClick();
  };

  handleHover = (event: React.MouseEvent) => {
    event.stopPropagation();
    this.props.onHover();
  };

  handleBlur = (event: React.MouseEvent) => {
    event.stopPropagation();
    this.props.onBlur();
  };

  render() {
    const {
      icon,
      title,
      description,
      cta,
      theme,
      dataHook,
      selected,
      onRef,
    } = this.props;

    return (
      <section
        className={classnames(styles.container, {
          [styles[theme]]: true,
          [styles.selected]: selected,
        })}
        ref={onRef}
        onClick={this.handleClick}
        data-hook={dataHook}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleBlur}
      >
        <aside className={styles.icon} data-hook="es-card-icon">
          {icon}
        </aside>

        <article className={styles.content} data-hook="es-card-content">
          <TextLabel
            shouldTranslate={false}
            value={title}
            type="T07"
            className={styles.title}
            dataHook="es-card-title"
            enableEllipsis={false}
          />
          {description && (
            <TextLabel
              type="T15"
              shouldTranslate={false}
              value={description}
              className={styles.description}
              dataHook="es-card-description"
              enableEllipsis={false}
            />
          )}
        </article>

        {cta && (
          <aside className={styles.cta} data-hook="es-card-cta">
            {cta}
          </aside>
        )}
      </section>
    );
  }
}
