export enum IconLayouts {
  Rounded = 'rounded',
  Circle = 'circle',
}

export enum IconColors {
  Primary = 'primary',
  Success = 'success',
}

export interface IIconProps {
  children?: React.ReactNode;
  layout?: IconLayouts;
  colors?: IconColors;
  size: number;
  bordered?: boolean;
}
