exports = module.exports = require("../../../node_modules/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._37wyd.control-button.btn-text{font-size:13px;height:18px;line-height:18px;width:100%}._37wyd.control-button.btn-text svg *{fill:currentColor}._37wyd.control-button.btn-text ._35SrA{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}._37wyd.control-button.btn-text._2c1WH ._35SrA{text-decoration:underline}._37wyd.control-button.btn-text._2j-8R{display:flex;align-items:center;justify-content:center;flex-grow:1}._37wyd.control-button.btn-text._2j-8R ._35SrA{max-width:calc(100% - 34px)}._37wyd.control-button.btn-text._2j-8R svg{width:18px;height:18px;margin-right:14px;flex-shrink:0}\n", ""]);

// Exports
exports.locals = {
	"container": "_37wyd",
	"text": "_35SrA",
	"selected": "_2c1WH",
	"withIcon": "_2j-8R"
};