import React from 'react';
import { TextLabel } from '@wix/wix-base-ui';
import { Panel } from '../Panel';
import { ISectionHeaderProps } from './SectionHeader.types';
import { st, classes } from './SectionHeader.st.css';

export const SectionHeader: React.FunctionComponent<ISectionHeaderProps> = ({
  title,
  link,
  sticky = false,
}) => (
  <Panel theme="theme-header" sticky={sticky}>
    <div className={st(classes.root)}>
      <TextLabel
        value={title}
        className={classes.title}
        shouldTranslate={false}
      />
      {link && link}
    </div>
  </Panel>
);
