import React from 'react';
import { Card } from '../Card';
import { Tooltip } from '../Tooltip';
import { noop } from '../../utils/noop';
import { ITooltipCardProps, ITooltipCardState } from './TooltipCard.types';
import { classes } from './TooltipCard.st.css';

export class TooltipCard extends React.Component<
  ITooltipCardProps,
  ITooltipCardState
> {
  static defaultProps = {
    onHover: noop,
    onBlur: noop,
  };

  state = {
    isTooltipOpen: false,
  };

  setIsTooltipOpen = (isTooltipOpen: boolean) => {
    return () => {
      if (this.state.isTooltipOpen !== isTooltipOpen) {
        this.setState({ isTooltipOpen });
      }
    };
  };

  getCta() {
    return (
      <Tooltip
        className={classes.tooltip}
        alignment="TOP"
        arrowDistance={222}
        maxWidth={240}
        interactive
        onClose={this.setIsTooltipOpen(false)}
        {...(this.state.isTooltipOpen && { isOpen: true })}
        {...this.props.tooltipProps}
      >
        {this.props.cta}
      </Tooltip>
    );
  }

  handleClick = () => {
    this.setState({ isTooltipOpen: true });
  };

  handleBlur = () => {
    this.props.onBlur();
    this.setState({ isTooltipOpen: false });
  };

  render() {
    return (
      <Card
        theme="theme-tooltip"
        {...this.props}
        onClick={this.handleClick}
        onBlur={this.handleBlur}
        cta={this.getCta()}
      />
    );
  }
}
