import React from 'react';
import classnames from 'classnames';
import { Button as BaseButton } from '@wix/wix-base-ui';
import { noop } from '../../utils/noop';
import { IButtonProps } from './Button.types';
import styles from './Button.scss';

export class Button extends React.Component<IButtonProps> {
  static defaultProps = {
    onClick: noop,
  };

  handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    this.props.onClick();
  };

  render() {
    const { children, dataHook } = this.props;

    return (
      <BaseButton
        className={classnames('btn-sm', styles.container)}
        onClick={this.handleClick}
        dataHook={dataHook}
      >
        {children}
      </BaseButton>
    );
  }
}
