(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@wix/wix-base-ui"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define("@wix/editor-search-ui", ["react", "@wix/wix-base-ui", "reactDOM"], factory);
	else if(typeof exports === 'object')
		exports["@wix/editor-search-ui"] = factory(require("react"), require("@wix/wix-base-ui"), require("react-dom"));
	else
		root["@wix/editor-search-ui"] = factory(root["React"], root["baseUILib"], root["ReactDOM"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__31__) {
return 