import React from 'react';
import { noop } from '../../utils/noop';
import { IPanelProps } from './Panel.types';
import { st, classes } from './Panel.st.css';

export const Panel: React.FunctionComponent<IPanelProps> = ({
  children,
  dataHook = 'es-panel',
  theme,
  sticky = false,
  onRef = noop,
}) => (
  <div
    className={st(classes.root, {
      [theme]: true,
      sticky,
    })}
    ref={onRef}
    data-hook={dataHook}
  >
    {children}
  </div>
);
