import React from 'react';

import { TextInput } from '@wix/wix-base-ui';
import DismissSmall from 'wix-ui-icons-common/dist/src/general/dist/components/DismissSmall';

import { Icon, IconLayouts, IconColors } from '../Icon';
import { Loader } from '../Loader';
import { ISearchInputProps } from './SearchInput.types';

import styles from './SearchInput.scss';

export class SearchInput extends React.Component<ISearchInputProps> {
  static emptyValue = '';
  static defaultProps = {
    value: SearchInput.emptyValue,
    dataHook: 'es-search-input',
    shouldFocus: false,
  };

  private readonly handleChange = (value: string) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  };

  private readonly handleClearButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    this.clear();
  };

  private readonly handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      this.clear();
    }
  };

  private clear() {
    const { onClear } = this.props;
    onClear && onClear();
  }

  render() {
    const { placeholder, dataHook, loading, value, shouldFocus } = this.props;

    return (
      <div
        className={styles.container}
        data-hook={dataHook}
        onKeyDown={this.handleKeyDown}
      >
        <TextInput
          className={styles.input}
          value={value}
          shouldTranslate={false}
          dataHook="es-search-input-input"
          placeholder={placeholder}
          autoSelect={shouldFocus}
          onChange={this.handleChange}
        />
        {value && !loading && (
          <button
            tabIndex={0}
            data-hook="es-search-input-clear-button"
            onClick={this.handleClearButtonClick}
            className={styles.clearButton}
          >
            <Icon
              size={18}
              layout={IconLayouts.Circle}
              colors={IconColors.Primary}
              bordered={false}
            >
              <DismissSmall size="14px" />
            </Icon>
          </button>
        )}
        {loading && (
          <div className={styles.loader}>
            <Loader />
          </div>
        )}
      </div>
    );
  }
}
