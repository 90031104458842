import React from 'react';
import CustomScroll from 'react-custom-scroll';

import { st, classes } from './Scrollable.st.css';

export const Scrollable: React.FunctionComponent = ({ children }) => (
  <div className={st(classes.root)}>
    <CustomScroll>
      <div className={classes.content}>{children}</div>
    </CustomScroll>
  </div>
);
