import * as Icons from './icons';

import { withScrollIntoView } from './hocs/scrollIntoView';

import { Card as _Card } from './components/Card';
import { ICardProps } from './components/Card/Card.types';

import { Panel as _Panel } from './components/Panel';
import { IPanelProps } from './components/Panel/Panel.types';

import { TooltipCard as _TooltipCard } from './components/TooltipCard';
import { ITooltipCardProps } from './components/TooltipCard/TooltipCard.types';

const Card = withScrollIntoView<ICardProps>(_Card);
const Panel = withScrollIntoView<IPanelProps>(_Panel);
const TooltipCard = withScrollIntoView<ITooltipCardProps>(_TooltipCard);

export { Icons, Card, Panel, TooltipCard };

export { SearchInput } from './components/SearchInput';
export { Layout } from './components/Layout';
export { Link } from './components/Link';
export { Icon, IconLayouts, IconColors } from './components/Icon';
export { SectionHeader } from './components/SectionHeader';
export { Scrollable } from './components/Scrollable';
export { Title } from './components/Title';
export { Loader } from './components/Loader';
export { Tooltip } from './components/Tooltip';
export { EmptyResults } from './components/EmptyResults';
export { Button } from './components/Button';
