import React from 'react';
import { Tooltip as WixBaseUITooltip, TooltipProps } from '@wix/wix-base-ui';
/*
  Importing tooltip assumes styles are already available in global scope of Editor.
  Please see ./storybook/preview-head.html for an example of styles available in editor for wix-base-ui.
 */

export const Tooltip: React.FunctionComponent<TooltipProps> = props => (
  <WixBaseUITooltip marginTop={4} {...props} />
);
